import "../scss/app.scss";
import React from "react";
import { Footer } from "../component/Footer";
import { Navbar } from "../component/Navbar";
import { Contact } from "../component/Contact";
import { Identifications } from "../component/Identifications";
import { ArticleList } from "../component/ArticleList";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Arrow from "../component/Arrow";
import { Section } from "../component/Section";
import AppHelmet from "../component/AppHelmet";
import { usePageInit } from "../component/hook/usePageInit";
import { Cookies } from "../component/Cookies";

const topComponents = {
  ContentfulNavbar: Navbar,
};

const bottomComponents = {
  ContentfulContact: Contact,
  ContentfulFooter: Footer,
  ContentfulIdentifications: Identifications,
};

function TagPage(props) {
  const { nodes } = props.data.allContentfulArticlePage;
  const { sections, tags, name, allArticles } = props.pageContext;
  const { popup } = usePageInit();

  return (
    <>
      <AppHelmet title={name} />
      {popup}
      {sections?.map(({ id, __typename, ...props }) => {
        const SectionTag = topComponents[__typename];
        return SectionTag && <SectionTag {...props} key={id} />;
      })}
      <TagHead name={name} />
      <ArticleList
        articles={nodes}
        allArticles={allArticles}
        tags={{ tags: tags.nodes, title: "Tagi" }}
      />
      {sections?.map(({ id, __typename, ...props }) => {
        const SectionTag = bottomComponents[__typename];
        return SectionTag && <SectionTag {...props} key={id} />;
      })}
      <Cookies />
    </>
  );
}

const TagHead = ({ name }) => (
  <Section width={11} className="bgc-gray-200 pt-5rem">
    <div className="col-12">
      <AnchorLink to={`/blog`} className="d-block pb-1rem">
        <Arrow direction="left" />
      </AnchorLink>
      <h1 className="c-black font-weight-bold fs-2 d-flex align-items-center cursor-pointer text-uppercase">
        # {name}
      </h1>
    </div>
  </Section>
);

export default TagPage;

export const articlesForTag = graphql`
  query($id: String!) {
    allContentfulArticlePage(
      filter: { tags: { elemMatch: { id: { eq: $id } } } }
    ) {
      nodes {
        id
        __typename
        title
        slug
        excerpt {
          raw
        }
        content {
          raw
        }
        mainImage {
          file {
            url
          }
        }
      }
    }
  }
`;
